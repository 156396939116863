import React from "react"
import ArbPdf from "../../assets/arbRules.pdf"

export default () => {
  return (
    <div id="instOff-clauses" className="instOff-clauses">
      <div className="instOff-clauses__container">
        <h2 className="instOff-clauses__heading">Model Clauses</h2>
        <h3 className="instOff-clauses__content-heading">
          Arbitration Clause (Existing Disputes) 
        </h3>
        <h4
          className="instOff-clauses__content"
          data-sal="slide-down"
          data-sal-delay="200"
          data-sal-duration="700"
        >
          A dispute having arisen between the parties concerning [details], the
          parties hereby agree that the dispute shall be referred to and finally
          resolved by arbitration under the{" "}
          <a href={ArbPdf} target="__blank" className="underlined-button ">
            Webnyay Arbitration Rules 2020
          </a>{" "}
          ("Rules"), available at{" "}
          <a href="https://www.webnyay.in/" className="underlined-button ">
            www.webnyay.in
          </a>
        </h4>
        <div className="u-margin-top-small"></div>

        <h4
          className="instOff-clauses__content"
          data-sal="slide-down"
          data-sal-delay="200"
          data-sal-duration="700"
        >
          The Arbitral Tribunal shall consist of [one/three] arbitrators who
          shall be appointed in accordance with the Rules. The seat of the
          arbitration shall be [City and/or Country]. The language of the
          arbitral proceedings shall be [English].
        </h4>
        <div className="u-margin-top-small"></div>
        <h4
          className="instOff-clauses__content"
          data-sal="slide-down"
          data-sal-delay="200"
          data-sal-duration="700"
        >
          The governing law of the contract [is/shall be] the substantive laws
          of [India].
        </h4>
        <div className="u-margin-top-large"></div>
        <h3 className="instOff-clauses__content-heading">
          Arbitration Clause (Future Disputes)
        </h3>

        <h4
          data-sal="slide-down"
          data-sal-delay="200"
          className="instOff-clauses__content"
          data-sal-duration="700"
        >
          Any dispute or claim arising out of or in connection with this
          contract, including any question regarding its existence, validity or
          termination, shall be referred to and finally resolved by arbitration
          under the Webnyay Arbitration Rules ("Rules") available at{" "}
          <a href="https://www.webnyay.in/" className="underlined-button ">
            www.webnyay.in
          </a>
          , which Rules are deemed to be incorporated by reference into this
          clause.
        </h4>

        <div className="u-margin-top-small"></div>
        <h4
          data-sal="slide-down"
          data-sal-delay="200"
          className="instOff-clauses__content"
          data-sal-duration="700"
        >
          The Arbitral Tribunal shall consist of [one/three] arbitrators who
          shall be appointed in accordance with the Rules. The seat of the
          arbitration shall be [City and/or Country]. The language of the
          arbitral proceedings shall be [English].
        </h4>
        <div className="u-margin-top-small"></div>
        <h4
          data-sal="slide-down"
          data-sal-delay="200"
          className="instOff-clauses__content"
          data-sal-duration="700"
        >
          The governing law of the contract shall be the substantive laws of
          [India].
        </h4>

        <div className="u-margin-top-medium"></div>
        <h4
          data-sal="slide-down"
          data-sal-delay="200"
          className="instOff-clauses__content"
          data-sal-duration="700"
        >
          合同中的示范仲裁条款由本合同引起或与之有关的任何纠纷，争执，分歧或要求，包括本合同的存在，有效性，解释，履行，违反或终止，或因本合同引起或与之有关的任何非合同，均应提交仲裁由Webnyay管理，并按照提交仲裁通知时有效的Webnyay仲裁规则进行。仲裁员的人数应为______（一或三）仲裁地点为（）仲裁程序中使用的语言为（英文或中文）
        </h4>
      </div>
      <div className="u-margin-top-medium"></div>
    </div>
  )
}
