import React from "react"
import ArbPdf from "../../assets/arbRules.pdf"

export default () => {
  return (
    <div id="instOff-questions" className="instOff-questions">
      <div className="instOff-questions__container">
        <h3 className="instOff-questions__heading">
          Looking to commence your claim?
        </h3>
        <h4 data-sal="slide-down" data-sal-delay="200" data-sal-duration="700">
          Pursuant to Rule 4 of the{" "}
          <a href={ArbPdf} target="__blank" className="underlined-button ">
            Webnyay Arbitration Rules 2020
          </a>{" "}
          , please email the ‘Request for Arbitration’ to admin@webnyay.in. If
          you would like to speak to us, please WhatsApp or call us at +91
          9958867718 / +91 9650312556.{" "}
        </h4>
        <div className="u-margin-top-small"></div>

        <h4 data-sal="slide-down" data-sal-delay="200" data-sal-duration="700">
          We will review the Request for Arbitration and revert on next steps.
          We will also let you know how you can pay the registration fees.
        </h4>
        <div className="u-margin-top-large"></div>
        <h3 className="instOff-questions__heading">
          Looking to appoint webnyay as only the appointing authority for your
          ad hoc arbitration?
        </h3>

        <h4 data-sal="slide-down" data-sal-delay="200" data-sal-duration="700">
          Pursuant to Rule 32 of the Webnyay Arbitration Rules 2020, please
          email us at admin@webnyay.in. If you would like to speak to us, please
          WhatsApp or call us at +91 9958867718 / +91 9650312556. In your email,
          please put “Appointing Authority” in the subject. Upon receipt of your
          email, we will revert on next steps.{" "}
        </h4>
      </div>
      <div className="u-margin-top-medium"></div>
    </div>
  )
}
