import React from "react"
import Clauses from "../components/institutionalOfferings/Clauses"
import Comparison from "../components/institutionalOfferings/comparison"
import Landing from "../components/institutionalOfferings/Landing"
import Questions from "../components/institutionalOfferings/Questions"
import Rules from "../components/institutionalOfferings/Rules"
import Layout from "../components/layout"

const AboutPage = () => (
  <Layout>
    <Landing />
    <Rules />
    <Questions />
    <Clauses />
    <Comparison />
  </Layout>
)

export default AboutPage
