import React from "react"
import ArbPdf from "../../assets/arbRules.pdf"
import Rafiki from "../../assets/images/vectors/justice/rafiki.svg"

export default function AboutLanding() {
  return (
    <React.Fragment>
      <div id="instOff-landing" className="instOff-landing">
        <div className="buffer only-short-height"></div>

        <div className="instOff-landing__container">
          <div className="instOff-landing__left">
            <h1
              className="instOff-landing__heading "
              data-sal="slide-down"
              data-sal-delay="200"
              data-sal-duration="700"
            >
              Institutional Offering
            </h1>
            <div className="u-margin-top-medium"></div>
            <h1
              className="instOff-landing__content text-white"
              data-sal="slide-down"
              data-sal-delay="200"
              data-sal-duration="700"
            >
              {" "}
              <a
                href={ArbPdf}
                target="__blank"
                className="underlined-button text-white "
              >
                Webnyay Arbitration Rules 2020
              </a>{" "}
              govern how arbitrations are conducted when Webnyay acts as the
              arbitration institution. Webnyay is the only body authorised to
              administer arbitrations under these Rules, including the scrutiny
              and approval of awards rendered in accordance with these Rules. At
              Webnyay, we believe in empowering you by allowing you to resolve
              your complaints, legal problems and disputes online.
            </h1>
            <div className="u-margin-top-medium"></div>
            <div className="u-margin-top-small"></div>
          </div>
          <div className="instOff-landing__grow"></div>
          <div className="instOff-landing__right">
            <img
              src={Rafiki}
              alt="instOff-landing page main image"
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="700"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
