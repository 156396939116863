import React from "react"
import ArbPdf from "../../assets/arbRules.pdf"

export default () => {
  return (
    <div id="instOff-rules" className="instOff-rules">
      <div className="instOff-rules__container">
        <h4
          className="product-landing__content text-black "
          data-sal="slide-down"
          data-sal-delay="200"
          data-sal-duration="700"
        >
          Under the{" "}
          <a href={ArbPdf} target="__blank" className="underlined-button ">
            Webnyay Arbitration Rules 2020
          </a>{" "}
          or even otherwise, Webnyay acts as the appointing authority and
          appoints arbitrators from the Webnyay Panel of Dispute Resolution
          Professionals.{" "}
        </h4>
        <div className="u-margin-top-small"></div>

        <h4
          className="product-landing__content text-black "
          data-sal="slide-down"
          data-sal-delay="200"
          data-sal-duration="700"
        >
          Webnyay recommends that parties insert its model clause in the
          contracts. We also have a model clause for existing disputes and
          facilitate execution of agreements online using digital signatures
          (and stamping, where applicable). Please contact us at
          admin@webnyay.in for further details or if you have any queries.
        </h4>
        <div className="u-margin-top-small"></div>

        <h4
          className="product-landing__content text-black"
          data-sal="slide-down"
          data-sal-delay="200"
          data-sal-duration="700"
        >
          Unlike other traditional arbitration institutions, we do not have a
          pre-defined and rigid fee structure. Once we receive the Request for
          Arbitration (and a Response to the Request for Arbitration, as
          applicable), we will let you know how much it would cost. This fee
          will include the fees of Webnyay for providing administrative
          services, managing the arbitration proceedings and providing access to
          the Webnyay Portal. We guarantee that our fees will be competitive and
          less than 1% of the amount in dispute or US$ 1,500 / INR 1,10,000
          whichever is higher (plus taxes).
        </h4>

        <h4
          className="product-landing__content text-black"
          data-sal="slide-down"
          data-sal-delay="200"
          data-sal-duration="700"
        >
          We will also let you know the fees of the Arbitral Tribunal.
        </h4>
      </div>
      <div className="u-margin-top-medium"></div>
    </div>
  )
}
