import React from "react"
import { anotherData } from "./conversion"

export default () => {
  return (
    <div id="instOff-comparison" className="instOff-comparison">
      <div className="instOff-comparison__container">
        <div className="background_row">
          <div className="background_1"></div>
          <div className="background_2"></div>
          <div className="background_3"></div>
        </div>

        <div className="instOff-comparison__innerContainer">
          {anotherData.map(singleRow => {
            return singleRow.map(singleElement => {
              return (
                <div
                  className="singleCell"
                  key={singleElement}
                  dangerouslySetInnerHTML={{ __html: singleElement }}
                ></div>
              )
            })
          })}
        </div>
      </div>
      <div className="u-margin-top-medium"></div>
    </div>
  )
}

//         <div className="instOff-comparison__row">
//           <div className="column_1">
//             <h3 className="row_header ">Procedural Step</h3>
//             {data.row1.map(singleItem => {
//               return (

//                 <div
//                   className="row_content"
//                   key={singleItem}
//                   dangerouslySetInnerHTML={{ __html: singleItem }}
//                 ></div>
//               )
//             })}
//           </div>
//           <div className="column_2">
//             <h3 className="row_header text-white ">Webnyay</h3>
//             {data.row2.map(singleItem => {
//               return (
//                 <div
//                   className="row_content"
//                   key={singleItem}
//                   dangerouslySetInnerHTML={{ __html: singleItem }}
//                 ></div>
//               )
//             })}
//           </div>
//           <div className="column_3">
//             <h3 className="row_header text-white ">Ad Hoc Arbitration </h3>
//             {data.row3.map(singleItem => {
//               return (
//                 <div
//                   className="row_content"
//                   key={singleItem}
//                   dangerouslySetInnerHTML={{ __html: singleItem }}
//                 ></div>
//               )
//             })}
//           </div>
//         </div>
