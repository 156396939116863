export let anotherData = [
  ["Procedural Steps", "Webnyay", "Ad Hoc Arbitration"],
  [
    " Appointment of arbitrator ",

    `Webnyay can appoint arbitrator(s) within 24 hours (if required). We appoint domain experts having the requisite experience and availability.  

<span class="break"> We guarantee that the arbitrator(s) will be independent,impartial and neutral to the parties and subject matter in the dispute. </span>`,

    "You will have to approach the courts to secure arbitrator appointments in India. Alternatively, we can still act as the appointing authority for your dispute. ",
  ],
  [
    "Fees",

    `
Unlike other traditional arbitration institutions, we do not have a pre-defined and rigid fee structure. Once we receive the Request for Arbitration and a Response to the Request for Arbitration, we will let you know how much it would cost. 

<span class="break">We guarantee that our fees will be competitive and less than what other arbitral institutions charge. It will be either 1% of the amount in dispute or US$ 1,500 / INR 1,10,000 (plus taxes), whichever is lower. Accordingly, the maximum you will pay (no matter what your amount in dispute is) is US$ 1,500 / INR 1,10,000 (plus taxes). The fees of the Arbitral Tribunal will be additional.</span>`,

    "You will have to negotiate the fees with the arbitrator(s) with no upper limit. ",
  ],
  [
    "Supervision of arbitration proceedings ",

    "Webnyay’s Secretariat supervises the conduct of arbitration proceedings. ",

    "There is no institutional supervision of proceedings.",
  ],
  [
    "Market leading platform for managing and conducting the arbitration proceedings ",

    "The arbitration proceedings can be easily managed and conducted online (end-to-end). You no longer have to use emails, calendars, chat messengers, cloud storages/drives or traditional video conferencing software (like Zoom, Webex, Teams). We provide it all on the Webnyay platform accessible on the web and on phone. ",

    "We provide the platform for ad hoc arbitrations as well. If you’re not keen to be governed by the Webnyay Arbitration Rules 2020 but still want your dispute to be resolved on the Webnyay platform, contact us.",
  ],
  [
    "Appointment of emergency arbitrator for urgent interim relief ",

    "The Webnyay Arbitration Rules 2020 provide a mechanism for the appointment of an emergency arbitrator to grant interim relief.",

    "You have no option but to approach the court of competent jurisdiction to seek interim relief, before the appointment of the arbitral tribunal. ",
  ],
  [
    "Scrutiny of awards",

    "Pursuant to the Rules, we will review the arbitral award. ",
    "There is no scrutiny of awards.  ",
  ],
  [
    "Technical Support ",

    "Our Secretariat comprises case managers (trained lawyers) as well as IT professionals to assist with any technical difficulties. ",

    "We provide technical support if you’re using the platform for an ad hoc arbitration.  ",
  ],
]
